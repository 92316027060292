@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-semibold;
  }
  h2 {
    @apply text-xl font-semibold;
  }
  h3 {
    @apply text-lg font-semibold;
  }
}

@layer components {
  .btn-main {
    @apply mt-4 px-5 py-1 text-main border-2 border-main rounded-lg hover:text-white hover:bg-main transition-all;
  }
  .btn-normal {
    @apply px-2 py-1 rounded-lg text-gray-900 bg-gray-200 font-semibold hover:bg-gray-300 transition-all;
  }
  .btn-primary {
    @apply bg-blue-500 hover:bg-blue-700 text-white;
  }
  .btn-secondary {
    @apply hover:bg-gray-700 text-gray-700 border-gray-300 border;
  }
  .btn-xs {
    @apply text-xs;
  }
  .btn-lg {
    @apply text-lg;
  }
  .btn-xl {
    @apply text-xl;
  }
  .input {
    @apply outline-none text-base;
  }
}

html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

html {
  font-family: 'Montserrat', sans-serif;
}
