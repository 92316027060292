.rc-md-editor .rc-md-navigation {
    border: 0;
    font-size: 18px;
    background: #fff;
    
}

.rc-md-editor {
    border: 0;
}

.rc-md-editor .editor-container>.section {
    border-right: 0;
}

.rc-md-editor .editor-container .sec-md .input {
    border-right: 1px solid #e4e4ed;
    font-size: 17px;
}

.rc-md-editor .rc-md-navigation .button-wrap .button {
    min-width: 28px;
}

.rc-md-editor .rc-md-navigation .button-wrap .rmel-iconfont {
    font-size: 1.3rem;
}

.custom-html-style p {
    font-size: 17px;
}

.custom-html-style a:hover {
    text-decoration: underline;
}

.custom-html-style pre {
    padding: 0;
    background: 0;
}

.custom-html-style pre code {
    background: 0;
    font-size: 16px!important;
}

.token.console {
    text-decoration: none!important;
    color: rgb(197, 200, 198)!important;
}

.custom-html-style ul {
    list-style-type: disc;
    font-size: 17px;
}


.custom-html-style ol {
    list-style-type: decimal;
    font-size: 17px;
}

.custom-html-style blockquote {
    border-left: 5px solid green;
}

.custom-html-style p code {
    background-color: rgba(96,125,139,.2);
    border-radius: 3px;
} 